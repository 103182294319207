"use strict";
/**
 * PIXELCOMPARE
 * Javascript image comparison
 * @author diamanthaxhimusa@gmail.com
 * customized by Tebaldi Erika
 */
(function() {
  var _extend = function(defaults, options) {
    var extended = {};
    var prop;
    for (prop in defaults) {
      if(Object.prototype.hasOwnProperty.call(defaults, prop)) {
        extended[prop] = defaults[prop];
      }
    }
    for (prop in options) {
      if(Object.prototype.hasOwnProperty.call(options, prop)) {
        extended[prop] = options[prop];
      }
    }
    return extended;
  };

  var _addClass = function(element, classname) {
    var arr;
    arr = element.className.split(" ");
    if(arr.indexOf(classname) == -1) {
      element.className += " " + classname;
    }
  };

  var _hasClass = function(element, className) {
    return new RegExp("(\\s|^)" + className + "(\\s|$)").test(
      element.className
    );
  };

  var _removeClass = function(element, className) {
    element.classList.remove(className);
  };

  var _wrap = function(element, tag, sliderOrientation) {
    var div = document.createElement(tag);
    _addClass(div, "pixelcompare-wrapper pixelcompare-" + sliderOrientation);
    element.parentElement.insertBefore(div, element);
    div.appendChild(element);
    return div;
  };

  var _createSlider = function(beforeDirection, afterDirection) {
    var pxcHandleNode = document.createElement("div");
    _addClass(pxcHandleNode, "pixelcompare-handle");

    var sliderChildNodeBeforeDirection = document.createElement("span");
    _addClass(
      sliderChildNodeBeforeDirection,
      "pixelcompare-" + beforeDirection + "-arrow"
    );
    pxcHandleNode.appendChild(sliderChildNodeBeforeDirection);
    var sliderChildNodeAfterDirection = document.createElement("span");
    _addClass(
      sliderChildNodeAfterDirection,
      "pixelcompare-" + afterDirection + "-arrow"
    );
    pxcHandleNode.appendChild(sliderChildNodeAfterDirection);

    pxcHandleNode.addEventListener("touchmove", function(e) {
      e.preventDefault();
    });
    return pxcHandleNode;
  };

  var options = {
    default_offset_pct: 0.5,
    orientation: "horizontal",
    overlay: false,
    hover: false,
    move_with_handle_only: false,
    click_to_move: false,
    showSlider: false,
  };

  var pxcContainers = document.querySelectorAll("[data-pixelcompare]");

  pxcContainers.forEach(function(pcContainer) {
    var sliderPct = options.default_offset_pct;
    var imageContainer = pcContainer;
    options.hover = pcContainer.hasAttribute("data-hover");
    options.showSlider = options.hover ? pcContainer.hasAttribute("data-show-slider") : options.showSlider;
    options.orientation = pcContainer.hasAttribute("data-vertical")
      ? "vertical"
      : "horizontal";
    var orientations = ["vertical", "horizontal", "sides"];

    var datasetOrientation = imageContainer.dataset.pixelcompareOrientation;
    var sliderOrientation = orientations.includes(datasetOrientation)
      ? datasetOrientation
      : options.orientation;
    var beforeDirection = sliderOrientation === "vertical" ? "down" : "left";
    var afterDirection = sliderOrientation === "vertical" ? "up" : "right";

    var container = _wrap(pcContainer, "div", sliderOrientation);

    var beforeImg = container.querySelectorAll("img")[0];
    beforeImg.draggable = false;
    var afterImg = container.querySelectorAll("img")[1];
    afterImg.draggable = false;
    _addClass(container, "pixelcompare-container");
    _addClass(beforeImg, "pixelcompare-before");
    _addClass(afterImg, "pixelcompare-after");
    var slider = null
    if(options.showSlider) {
      slider = _createSlider(beforeDirection, afterDirection);
      container.appendChild(slider);
    }
    if(options.overlay) {
      var overlayNode = document.createElement("div");
      _addClass(overlayNode, "pixelcompare-overlay");
      container.appendChild(overlayNode);
    }

    var calcOffset = function(dimensionPct) {
      var w = beforeImg.getBoundingClientRect().width;
      var h = beforeImg.getBoundingClientRect().height;
      return {
        w: w + "px",
        h: h + "px",
        wp: dimensionPct * 100,
        cw: dimensionPct * w + "px",
        ch: dimensionPct * h + "px",
      };
    };

    var adjustContainer = function(offset) {
      if(sliderOrientation === "vertical") {
        beforeImg.style.clip =
          "rect(0, " + offset.w + ", " + offset.ch + ", 0)";
        afterImg.style.clip =
          "rect(" + offset.ch + ", " + offset.w + ", " + offset.h + ", 0)";
      } else if(sliderOrientation === "sides") {
        beforeImg.style.clipPath = `polygon(0% ${2 * (50 - offset.wp)}%, ${
          2 * offset.wp
        }% 100%, 0% 100%)`;
        afterImg.style.clipPath = `polygon(100% ${2 * (100 - offset.wp)}%, ${
          -2 * (50 - offset.wp)
        }% 0%, 100% 0%)`;
      } else {
        beforeImg.style.clip =
          "rect(0, " + offset.cw + ", " + offset.h + ", 0)";
        afterImg.style.clip =
          "rect(0, " + offset.w + "," + offset.h + "," + offset.cw + ")";
      }
      container.style.height = offset.h;
    };

    var adjustSlider = function(pct) {
      var offset = calcOffset(pct);
      if(slider) {
        if(sliderOrientation === "vertical") {
          slider.style.top = offset.ch;
        } else {
          slider.style.left = offset.cw;
        }
      }
      adjustContainer(offset);
    };

    // Return the number specified or the min/max number if it outside the range given.
    var minMaxNumber = function(num, min, max) {
      return Math.max(min, Math.min(max, num));
    };

    // Calculate the slider percentage based on the position.
    var getSliderPercentage = function(positionX, positionY) {
      var sliderPercentage =
        sliderOrientation === "vertical"
          ? (positionY - offsetY) / imgHeight
          : (positionX - offsetX) / imgWidth;
      return minMaxNumber(sliderPercentage, 0, 1);
    };

    window.addEventListener("resize.pixelcompare", function(e) {
      adjustSlider(sliderPct);
    });

    var offsetX = 0;
    var offsetY = 0;
    var imgWidth = 0;
    var imgHeight = 0;
    // var onMoveStart = function(e) {
    //   if(
    //     ((e.distX > e.distY && e.distX < -e.distY) ||
    //       (e.distX < e.distY && e.distX > -e.distY)) &&
    //     sliderOrientation !== "vertical"
    //   ) {
    //     e.preventDefault();
    //   } else if(
    //     ((e.distX < e.distY && e.distX < -e.distY) ||
    //       (e.distX > e.distY && e.distX > -e.distY)) &&
    //     sliderOrientation === "vertical"
    //   ) {
    //     e.preventDefault();
    //   }
    //   _addClass(container, "active");  
    //   offsetX = container.offsetLeft;
    //   offsetY = container.offsetTop;
    //   imgWidth = beforeImg.getBoundingClientRect().width;
    //   imgHeight = beforeImg.getBoundingClientRect().height;
    // };
    // var onMove = function(e) {
    //   if(_hasClass(container, "active")) {
    //     sliderPct = getSliderPercentage(
    //       e.pageX || e.changedTouches[0].pageX,
    //       e.pageY || e.changedTouches[0].pageY
    //     );
    //     adjustSlider(sliderPct);
    //   }
    // };
    // var onMoveEnd = function() {
    //   _removeClass(container, "active");
    // };

    // if(options.hover) {
    //   container.addEventListener("mouseenter", onMoveStart);
    //   container.addEventListener("mouseleave", onMoveEnd);
    //   container.addEventListener("mousemove", onMove);
    // } else {
    //   var moveTarget = options.move_with_handle_only ? slider : container;
    //   window.addEventListener("mouseup", onMoveEnd);
    //   container.addEventListener("mousemove", onMove);
    //   moveTarget.addEventListener("mousedown", onMoveStart);
    //   moveTarget.addEventListener("touchstart", onMoveStart);
    //   container.addEventListener("touchmove", onMove);
    //   window.addEventListener("touchend", onMoveEnd);
    // }
    var isTouch = false;
    //window.addEventListener("mouseup", function(){ isTouch = false }); 
    container.addEventListener("mousedown", function(){ isTouch = true });
    container.addEventListener("touchstart", function(){ isTouch = true });
    window.addEventListener("touchend", function(){ isTouch = false });

    var direction = "",
    oldx = 0,
    mousemovemethod = function (e) {
      
        if (e.pageX < oldx) {
          direction = "left"
        } else if (e.pageX > oldx) {
          direction = "right"
        }

        if (isTouch && (direction == "right") && (sliderPct == '0.5') )  {
          
          sliderPct = '1', '0';
          imgWrap.addClass('right');
          imgWrap.removeClass('left');
          adjustSlider(sliderPct);
          isTouch = false;
          direction = "";
        } else if (isTouch && (direction == "left") && (sliderPct == '0.5')){
          
          sliderPct = '0', '0';
          imgWrap.addClass('left');
          imgWrap.removeClass('right'); 
          adjustSlider(sliderPct);
          isTouch = false;
          direction = "";
        } else if (isTouch && ((direction == "left") || (direction == "right")) && ((sliderPct == '0') || (sliderPct == '1'))){
         
          sliderPct = '0.5', '0';
          imgWrap.removeClass('left'); 
          imgWrap.removeClass('right');
          adjustSlider(sliderPct);
          isTouch = false;
          direction = "";
        }
      
        oldx = e.pageX;
            
    }
    $('.pixelcompare-wrap').on('mousemove', mousemovemethod );
    
   
    var directiont = "",
    oldxt = 0,
    touchmovemethod = function (e) {
      var currentX = e.originalEvent.touches[0].clientX;

        if (currentX < oldxt) {
          directiont = "left"
        } else if (currentX > oldxt) {
          directiont = "right"
        }

        if (isTouch && (directiont == "right") && (sliderPct == '0.5') )  {
          
          sliderPct = '1', '0';
          imgWrap.addClass('right');
          imgWrap.removeClass('left');
          adjustSlider(sliderPct);
          directiont = "";
        } else if (isTouch && (directiont == "left") && (sliderPct == '0.5')){
         
          sliderPct = '0', '0';
          imgWrap.addClass('left');
          imgWrap.removeClass('right'); 
          adjustSlider(sliderPct);
          directiont = "";
        } else if (isTouch && ((directiont == "left") || (directiont == "right")) && ((sliderPct == '0') || (sliderPct == '1'))){
          
          sliderPct = '0.5', '0';
          imgWrap.removeClass('left'); 
          imgWrap.removeClass('right');
          adjustSlider(sliderPct); 
          direction = "";
        }
      
        oldxt = e.originalEvent.touches[0].clientX; 
            
    }
    $('.pixelcompare-wrap').on("touchmove", touchmovemethod);
 
    container
      .querySelector("img")
      .addEventListener("touchstart mousedown", function(event) {
        event.preventDefault();
      });

    if(options.click_to_move) {
      container.on("click", function(e) {
        offsetX = container.offset().left;
        offsetY = container.offset().top;
        imgWidth = beforeImg.width();
        imgHeight = beforeImg.height();
        sliderPct = getSliderPercentage(e.pageX, e.pageY);
        adjustSlider(sliderPct);
      });
    }

    var linkLeft = $('.linkToLeft');
    var linkRight = $('.linkToRight');
    var linkCenter = $('.linkToCenter');
    var imgWrap = $('.pixelcompare-wrap');
 
    linkLeft.on("click", function(e) {
      
      sliderPct = '1', '0';
      imgWrap.addClass('right');
      imgWrap.removeClass('left');
      adjustSlider(sliderPct);
    });
    linkRight.on("click", function(e) {
      
      sliderPct = '0', '0';
      imgWrap.addClass('left');
      imgWrap.removeClass('right');
      adjustSlider(sliderPct);
    });
    linkCenter.on("click", function(e) {
      
      sliderPct = '0.5', '0';
      imgWrap.removeClass('left'); 
      imgWrap.removeClass('right');
      adjustSlider(sliderPct);
    });

    window.dispatchEvent(new Event("resize.pixelcompare"));
    
    window.onresize = function(event) {
      adjustSlider(sliderPct)
  };
  });
})();
